var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center",staticStyle:{"gap":"1rem"}},_vm._l((_vm.data),function(card,index){return _c('div',{key:index,staticClass:"col-12 col-sm-5 col-md d-flex justify-content-between align-items-center rounded p-2 glow-box bg-dark",style:({
      boxShadow: _vm.activeCard === index ? `inset 0 0 0 2px ${card.color}` : 'none',
    }),on:{"mouseenter":function($event){return _vm.showCursorGlow(index)},"mouseleave":function($event){return _vm.hideCursorGlow(index)},"mousemove":(event) => _vm.handleMouseMove(event, index),"click":function($event){return _vm.toggleActive(index)}}},[(_vm.showGlow)?_c('div',{staticClass:"cursor-glow",style:({
        left: _vm.cursorX[index] + 'px',
        top: _vm.cursorY[index] + 'px',
        ..._vm.glowStyle(card.color),
        opacity: _vm.showGlow[index] ? '1' : '0',
      })}):_vm._e(),_c('div',{class:`d-flex align-items-center justify-content-center`,style:({
        width: '60px',
        height: '60px',
        backgroundColor: card.color,
        'border-radius': _vm.computeBorderRadius(card.tipo),
        '-webkit-box-shadow': `0px -1px 33px -8px ${card.color}`,
        '-moz-box-shadow': `0px -1px 33px -8px ${card.color}`,
        'box-shadow': `0px -1px 33px -8px ${card.color}`,
      })},[_c('i',{class:`fas fa-${card.iconName} ${_vm.computeAnimateIcon(card.animated, index)}`,staticStyle:{"font-size":"1.5rem"}})]),_c('div',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"flex-grow":"1","max-width":"calc(100% - 60px)"}},[_c('div',{class:`title-container`},[_c('span',{staticClass:"font-weight-bold",class:[_vm.showGlow[index] && _vm.animatedState[index] && _vm.shouldAnimateTitle[index] ? 'title-animate' : 'title-static']},[_vm._v(" "+_vm._s(card.title)+" ")])]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"40px"}},[_vm._v(_vm._s(_vm.evaluateValue(card.value)))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }