<script>
import Swal from 'sweetalert2'
import BVForm from '@/components/BVForm/BVForm.vue'
import CardTable from "@/components/cardTable/CardTable.vue";

export default {
    name: 'VinculacionDePostulantes',
    components: {
        BVForm,
        CardTable
    },
    data() {
        return {
            documentosExistentes: [],
            aspirantes: [],
            schemaDeSeleccion: [],

            postulanteDatos: {},
            opcionesDeEvaluacion: [],
            evaluarSchema: [
                {
                    name: 'estadoEvaluacion',
                    type: 'select',
                    label: 'Vinculación de postulantes',
                    options: []
                }
            ],

            resumenInCards: [
                {
                    title: "Vinculados",
                    value: () => this.aspirantes.filter(postulante => postulante.vinculacionEvaluacion.estado == "Aprobado").length,
                    iconName: "circle-check",
                    color: "#16a275",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("Aprobado").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                },
                {
                    title: "Pendientes",
                    value: () => this.aspirantes.filter(postulante => postulante.vinculacionEvaluacion.estado == "Pendiente").length,
                    iconName: "clock",
                    color: "#fe721c",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("Pendiente").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                },
                {
                    title: "Rechazados",
                    value: () => this.aspirantes.filter(postulante => postulante.vinculacionEvaluacion.estado == "Rechazado").length,
                    iconName: "ban",
                    color: "rgba(223, 18, 46, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("Rechazado").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                },
                {
                    title: "Total postulantes",
                    value: () => this.aspirantes.length,
                    iconName: "users",
                    color: "rgba(71, 136, 255, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                }
            ]
        }
    },
    async mounted() {


        await this.getPostulantes();
        await this.getDocumentos();

        const response = await this.$store.getters.fetchGet({ path: 'ReclutamientoYSeleccion/Seleccion/estados' })
        const data = await response.json()
        this.opcionesDeEvaluacion = data.map(element => ({ id: element.id, descripcion: element.estado }))
        this.evaluarSchema[0].options = this.opcionesDeEvaluacion
    },
    methods: {
        async getPostulantes() {
            const response = await this.$store.getters.fetchGet({ path: `Postulacion/postulantes/${this.$route.params.vacanteId}` });
            const vacantes = await response.json();

            this.aspirantes = vacantes.filter(vacante => {
                if (vacante.seleccionEvaluacion) {
                    return vacante.seleccionEvaluacion.estado == 'Aprobado'
                }
                else {
                    return false
                }
            })
            console.log("Aspirantes", this.aspirantes);

            let optionsSchema = this.aspirantes
                .filter(aspirante => aspirante.vinculacionId == undefined)
                .map(aspirante => {
                    return {
                        value: aspirante.id,
                        text: aspirante.nombres + ' ' + aspirante.apellidos
                    }
                })

            optionsSchema = [...optionsSchema]

            this.schemaDeSeleccion = [
                {
                    name: 'postulanteSeleccionado',
                    type: 'select',
                    label: 'Seleccione el postulante que sera vinculado a la empresa',
                    options: optionsSchema
                }
            ]
            this.schemaDeSeleccion = [...this.schemaDeSeleccion]
        },

        async getDocumentos() {
            this.$store.getters.fetchGet({ path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${this.$route.params.vacanteId}` })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Error en el backend o DB');
                })
                .then(data => {
                    this.documentosExistentes = data;
                    console.log("Documentos", data);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getValoracionByPostulacion(postulacionId) {
            let valoraciones = this.documentosExistentes.filter(valoracion => valoracion.postulacionId == postulacionId && valoracion.puntaje != null);
            let promedio = valoraciones.reduce((total, valoracion) => total + valoracion.puntaje, 0) / valoraciones.length;
            if (promedio) {
                return promedio
            }
            else {
                return 0
            }
        },
        evaluarPostulacionTalentoInterno(id) {
            return id ? 'Si' : 'No'
        },

        openModalEvaluar(data) {
            this.postulanteDatos = data;
            this.$bvModal.show('modal-evaluar');
        },

        evaluarYVincular(self) {

            if (this.postulanteDatos.vinculacionEvaluacion.estado == 'Aprobado') {
                Swal.fire({
                    title: 'Error!',
                    text: 'No es posible realizar cambios en una vinculación aprobada',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
                return
            }

            // TODO: Si el numero de identificacion ya existe no lo puede vincular

            self.loader = true
            this.$store.getters.fetchPut({
                path: `Postulacion/evaluar/vinculacion/${this.postulanteDatos.id}/${self.data.estadoEvaluacion}`,
                data: self.data
            })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al evaluar la vinculación')
                })
                .then(async (result) => {
                    console.log(result.vinculacionEvaluacion.estado);

                    if (result.vinculacionEvaluacion.estado == 'Aprobado') {
                        let response;

                        if (result.personaId == null) {
                            response = await this.$store.getters.fetchPost({
                                path: `Persona/VinculacionPersonaByPostulacion/${this.postulanteDatos.id}/${this.$route.params.vacanteId}`
                            })
                        }
                        else {
                            response = await this.$store.getters.fetchPost({
                                path: `HistorialDeCargo/VinculacionInterna/${result.personaId}/${this.$route.params.vacanteId}`
                            })
                        }

                        if (response.ok) {
                            Swal.fire({
                                title: 'Vinculación exitosa',
                                text: 'El postulante ha sido vinculado a la empresa',
                                icon: 'success',
                                confirmButtonText: 'Aceptar'
                            })
                        }
                        else {
                            throw new Error('Error al vincular el postulante')
                        }
                    }

                    const index = this.aspirantes.findIndex(item => item.id == result.id)
                    this.aspirantes[index] = result
                    this.aspirantes = [...this.aspirantes]
                })
                .catch(error => {
                    Swal.fire({
                        title: 'Error!',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    })
                })
                .finally(() => {
                    self.loader = false
                })


        },
        getIcon(value) {
            if (value == 'Pendiente') {
                return 'fas fa-clock text-warning'
            }
            else if (value == 'Aprobado') {
                return 'fas fa-check-circle text-success'
            }
            else if (value == 'Rechazado') {
                return 'fas fa-times-circle text-danger'
            }
        },
    }

}


</script>

<template>
    <div class="container-fluid">
        <b-modal id="modal-evaluar" class="modal-sq" size="lg" hide-footer>
            <template #modal-header="{ close }">
                <div class="d-flex justify-content-between" style="width: 100%;">
                    <p class="mb-0 h4"><strong>Estado de la evaluación</strong></p>
                    <div @click="close()" class="btn btn-danger btn-sm btn-pm">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
            </template>
            <div>
                <p>{{ postulanteDatos.nombres + ' ' + postulanteDatos.apellidos }}</p>
            </div>
            <BVForm :schema="evaluarSchema" :callBackSubmit="evaluarYVincular">
            </BVForm>
        </b-modal>

        <card>
            <template v-slot:headerTitle>
                <div class="d-flex">
                    <div class="mr-2">
                        <router-link :to="{ name: 'AdministradorDeVacantes' }">
                            <i class="fas fa-arrow-left fa-lg px-1 text-primary"></i>
                        </router-link>
                    </div>
                    <h4 class="card-title"><b>Vinculación de postulantes</b></h4>
                </div>
            </template>
            <div class="px-4">
                <CardTable class="px-3" :data="resumenInCards"></CardTable>
            </div>
            <div class="table-responsive px-4 mt-3">
                <table id="datatable_cargos" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                        <tr>
                            <th>Nombres y apellidos</th>
                            <th>Numero de documento</th>
                            <th>Valoración de selección</th>
                            <th>¿Es talento interno?</th>
                            <th>Vinculación</th>
                            <th>Controles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="aspirante in aspirantes" :key="aspirante.id">
                            <td>{{ aspirante.nombres + ' ' + aspirante.apellidos }}</td>
                            <td>{{ aspirante.numeroDocumento }}</td>
                            <td>{{ getValoracionByPostulacion(aspirante.id) }}</td>

                            <td>{{ evaluarPostulacionTalentoInterno(aspirante.personaId) }}</td>
                            <td>
                                <i :class="getIcon(aspirante.vinculacionEvaluacion.estado)" class="mr-2"></i>
                                {{ aspirante.vinculacionEvaluacion.estado }}

                            </td>
                            <td>
                                <div class="btn btn-sm btn-primary" @click="openModalEvaluar(aspirante)">
                                    Evaluar
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card>

    </div>
</template>

<style scoped>
.btn-pm {
    position: relative;
    min-width: 20px;
    height: 20px;
    text-align: center;
    padding: 2px 5px;
    margin: 0px 2px;
    border: none;
    border-radius: 5px;
}

.btn-pm i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>